/* eslint-disable react/prop-types */
import React from 'react';

export default function Bullets({className}) {
  return (
    <svg
      className={className}
      viewBox="0 0 192 192"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5Z" />
      <path d="M10 109C10 111.761 7.76142 114 5 114C2.23858 114 0 111.761 0 109C0 106.239 2.23858 104 5 104C7.76142 104 10 106.239 10 109Z" />
      <path d="M10 57C10 59.7614 7.76142 62 5 62C2.23858 62 0 59.7614 0 57C0 54.2386 2.23858 52 5 52C7.76142 52 10 54.2386 10 57Z" />
      <path d="M10 161C10 163.761 7.76142 166 5 166C2.23858 166 0 163.761 0 161C0 158.239 2.23858 156 5 156C7.76142 156 10 158.239 10 161Z" />
      <path d="M10 31C10 33.7614 7.76142 36 5 36C2.23858 36 0 33.7614 0 31C0 28.2386 2.23858 26 5 26C7.76142 26 10 28.2386 10 31Z" />
      <path d="M10 135C10 137.761 7.76142 140 5 140C2.23858 140 0 137.761 0 135C0 132.239 2.23858 130 5 130C7.76142 130 10 132.239 10 135Z" />
      <path d="M10 83C10 85.7614 7.76142 88 5 88C2.23858 88 0 85.7614 0 83C0 80.2386 2.23858 78 5 78C7.76142 78 10 80.2386 10 83Z" />
      <path d="M10 187C10 189.761 7.76142 192 5 192C2.23858 192 0 189.761 0 187C0 184.239 2.23858 182 5 182C7.76142 182 10 184.239 10 187Z" />
      <path d="M114 5C114 7.76142 111.761 10 109 10C106.239 10 104 7.76142 104 5C104 2.23858 106.239 0 109 0C111.761 0 114 2.23858 114 5Z" />
      <path d="M114 109C114 111.761 111.761 114 109 114C106.239 114 104 111.761 104 109C104 106.239 106.239 104 109 104C111.761 104 114 106.239 114 109Z" />
      <path d="M114 57C114 59.7614 111.761 62 109 62C106.239 62 104 59.7614 104 57C104 54.2386 106.239 52 109 52C111.761 52 114 54.2386 114 57Z" />
      <path d="M114 161C114 163.761 111.761 166 109 166C106.239 166 104 163.761 104 161C104 158.239 106.239 156 109 156C111.761 156 114 158.239 114 161Z" />
      <path d="M114 31C114 33.7614 111.761 36 109 36C106.239 36 104 33.7614 104 31C104 28.2386 106.239 26 109 26C111.761 26 114 28.2386 114 31Z" />
      <path d="M114 135C114 137.761 111.761 140 109 140C106.239 140 104 137.761 104 135C104 132.239 106.239 130 109 130C111.761 130 114 132.239 114 135Z" />
      <path d="M114 83C114 85.7614 111.761 88 109 88C106.239 88 104 85.7614 104 83C104 80.2386 106.239 78 109 78C111.761 78 114 80.2386 114 83Z" />
      <path d="M114 187C114 189.761 111.761 192 109 192C106.239 192 104 189.761 104 187C104 184.239 106.239 182 109 182C111.761 182 114 184.239 114 187Z" />
      <path d="M36 5C36 7.76142 33.7614 10 31 10C28.2386 10 26 7.76142 26 5C26 2.23858 28.2386 0 31 0C33.7614 0 36 2.23858 36 5Z" />
      <path d="M36 109C36 111.761 33.7614 114 31 114C28.2386 114 26 111.761 26 109C26 106.239 28.2386 104 31 104C33.7614 104 36 106.239 36 109Z" />
      <path d="M36 57C36 59.7614 33.7614 62 31 62C28.2386 62 26 59.7614 26 57C26 54.2386 28.2386 52 31 52C33.7614 52 36 54.2386 36 57Z" />
      <path d="M36 161C36 163.761 33.7614 166 31 166C28.2386 166 26 163.761 26 161C26 158.239 28.2386 156 31 156C33.7614 156 36 158.239 36 161Z" />
      <path d="M36 31C36 33.7614 33.7614 36 31 36C28.2386 36 26 33.7614 26 31C26 28.2386 28.2386 26 31 26C33.7614 26 36 28.2386 36 31Z" />
      <path d="M36 135C36 137.761 33.7614 140 31 140C28.2386 140 26 137.761 26 135C26 132.239 28.2386 130 31 130C33.7614 130 36 132.239 36 135Z" />
      <path d="M36 83C36 85.7614 33.7614 88 31 88C28.2386 88 26 85.7614 26 83C26 80.2386 28.2386 78 31 78C33.7614 78 36 80.2386 36 83Z" />
      <path d="M36 187C36 189.761 33.7614 192 31 192C28.2386 192 26 189.761 26 187C26 184.239 28.2386 182 31 182C33.7614 182 36 184.239 36 187Z" />
      <path d="M140 5C140 7.76142 137.761 10 135 10C132.239 10 130 7.76142 130 5C130 2.23858 132.239 0 135 0C137.761 0 140 2.23858 140 5Z" />
      <path d="M140 109C140 111.761 137.761 114 135 114C132.239 114 130 111.761 130 109C130 106.239 132.239 104 135 104C137.761 104 140 106.239 140 109Z" />
      <path d="M140 57C140 59.7614 137.761 62 135 62C132.239 62 130 59.7614 130 57C130 54.2386 132.239 52 135 52C137.761 52 140 54.2386 140 57Z" />
      <path d="M140 161C140 163.761 137.761 166 135 166C132.239 166 130 163.761 130 161C130 158.239 132.239 156 135 156C137.761 156 140 158.239 140 161Z" />
      <path d="M140 31C140 33.7614 137.761 36 135 36C132.239 36 130 33.7614 130 31C130 28.2386 132.239 26 135 26C137.761 26 140 28.2386 140 31Z" />
      <path d="M140 135C140 137.761 137.761 140 135 140C132.239 140 130 137.761 130 135C130 132.239 132.239 130 135 130C137.761 130 140 132.239 140 135Z" />
      <path d="M140 83C140 85.7614 137.761 88 135 88C132.239 88 130 85.7614 130 83C130 80.2386 132.239 78 135 78C137.761 78 140 80.2386 140 83Z" />
      <path d="M140 187C140 189.761 137.761 192 135 192C132.239 192 130 189.761 130 187C130 184.239 132.239 182 135 182C137.761 182 140 184.239 140 187Z" />
      <path d="M62 5C62 7.76142 59.7614 10 57 10C54.2386 10 52 7.76142 52 5C52 2.23858 54.2386 0 57 0C59.7614 0 62 2.23858 62 5Z" />
      <path d="M62 109C62 111.761 59.7614 114 57 114C54.2386 114 52 111.761 52 109C52 106.239 54.2386 104 57 104C59.7614 104 62 106.239 62 109Z" />
      <path d="M62 57C62 59.7614 59.7614 62 57 62C54.2386 62 52 59.7614 52 57C52 54.2386 54.2386 52 57 52C59.7614 52 62 54.2386 62 57Z" />
      <path d="M62 161C62 163.761 59.7614 166 57 166C54.2386 166 52 163.761 52 161C52 158.239 54.2386 156 57 156C59.7614 156 62 158.239 62 161Z" />
      <path d="M62 31C62 33.7614 59.7614 36 57 36C54.2386 36 52 33.7614 52 31C52 28.2386 54.2386 26 57 26C59.7614 26 62 28.2386 62 31Z" />
      <path d="M62 135C62 137.761 59.7614 140 57 140C54.2386 140 52 137.761 52 135C52 132.239 54.2386 130 57 130C59.7614 130 62 132.239 62 135Z" />
      <path d="M62 83C62 85.7614 59.7614 88 57 88C54.2386 88 52 85.7614 52 83C52 80.2386 54.2386 78 57 78C59.7614 78 62 80.2386 62 83Z" />
      <path d="M62 187C62 189.761 59.7614 192 57 192C54.2386 192 52 189.761 52 187C52 184.239 54.2386 182 57 182C59.7614 182 62 184.239 62 187Z" />
      <path d="M166 5C166 7.76142 163.761 10 161 10C158.239 10 156 7.76142 156 5C156 2.23858 158.239 0 161 0C163.761 0 166 2.23858 166 5Z" />
      <path d="M166 109C166 111.761 163.761 114 161 114C158.239 114 156 111.761 156 109C156 106.239 158.239 104 161 104C163.761 104 166 106.239 166 109Z" />
      <path d="M166 57C166 59.7614 163.761 62 161 62C158.239 62 156 59.7614 156 57C156 54.2386 158.239 52 161 52C163.761 52 166 54.2386 166 57Z" />
      <path d="M166 161C166 163.761 163.761 166 161 166C158.239 166 156 163.761 156 161C156 158.239 158.239 156 161 156C163.761 156 166 158.239 166 161Z" />
      <path d="M166 31C166 33.7614 163.761 36 161 36C158.239 36 156 33.7614 156 31C156 28.2386 158.239 26 161 26C163.761 26 166 28.2386 166 31Z" />
      <path d="M166 135C166 137.761 163.761 140 161 140C158.239 140 156 137.761 156 135C156 132.239 158.239 130 161 130C163.761 130 166 132.239 166 135Z" />
      <path d="M166 83C166 85.7614 163.761 88 161 88C158.239 88 156 85.7614 156 83C156 80.2386 158.239 78 161 78C163.761 78 166 80.2386 166 83Z" />
      <path d="M166 187C166 189.761 163.761 192 161 192C158.239 192 156 189.761 156 187C156 184.239 158.239 182 161 182C163.761 182 166 184.239 166 187Z" />
      <path d="M88 5C88 7.76142 85.7614 10 83 10C80.2386 10 78 7.76142 78 5C78 2.23858 80.2386 0 83 0C85.7614 0 88 2.23858 88 5Z" />
      <path d="M88 109C88 111.761 85.7614 114 83 114C80.2386 114 78 111.761 78 109C78 106.239 80.2386 104 83 104C85.7614 104 88 106.239 88 109Z" />
      <path d="M88 57C88 59.7614 85.7614 62 83 62C80.2386 62 78 59.7614 78 57C78 54.2386 80.2386 52 83 52C85.7614 52 88 54.2386 88 57Z" />
      <path d="M88 161C88 163.761 85.7614 166 83 166C80.2386 166 78 163.761 78 161C78 158.239 80.2386 156 83 156C85.7614 156 88 158.239 88 161Z" />
      <path d="M88 31C88 33.7614 85.7614 36 83 36C80.2386 36 78 33.7614 78 31C78 28.2386 80.2386 26 83 26C85.7614 26 88 28.2386 88 31Z" />
      <path d="M88 135C88 137.761 85.7614 140 83 140C80.2386 140 78 137.761 78 135C78 132.239 80.2386 130 83 130C85.7614 130 88 132.239 88 135Z" />
      <path d="M88 83C88 85.7614 85.7614 88 83 88C80.2386 88 78 85.7614 78 83C78 80.2386 80.2386 78 83 78C85.7614 78 88 80.2386 88 83Z" />
      <path d="M88 187C88 189.761 85.7614 192 83 192C80.2386 192 78 189.761 78 187C78 184.239 80.2386 182 83 182C85.7614 182 88 184.239 88 187Z" />
      <path d="M192 5C192 7.76142 189.761 10 187 10C184.239 10 182 7.76142 182 5C182 2.23858 184.239 0 187 0C189.761 0 192 2.23858 192 5Z" />
      <path d="M192 109C192 111.761 189.761 114 187 114C184.239 114 182 111.761 182 109C182 106.239 184.239 104 187 104C189.761 104 192 106.239 192 109Z" />
      <path d="M192 57C192 59.7614 189.761 62 187 62C184.239 62 182 59.7614 182 57C182 54.2386 184.239 52 187 52C189.761 52 192 54.2386 192 57Z" />
      <path d="M192 161C192 163.761 189.761 166 187 166C184.239 166 182 163.761 182 161C182 158.239 184.239 156 187 156C189.761 156 192 158.239 192 161Z" />
      <path d="M192 31C192 33.7614 189.761 36 187 36C184.239 36 182 33.7614 182 31C182 28.2386 184.239 26 187 26C189.761 26 192 28.2386 192 31Z" />
      <path d="M192 135C192 137.761 189.761 140 187 140C184.239 140 182 137.761 182 135C182 132.239 184.239 130 187 130C189.761 130 192 132.239 192 135Z" />
      <path d="M192 83C192 85.7614 189.761 88 187 88C184.239 88 182 85.7614 182 83C182 80.2386 184.239 78 187 78C189.761 78 192 80.2386 192 83Z" />
      <path d="M192 187C192 189.761 189.761 192 187 192C184.239 192 182 189.761 182 187C182 184.239 184.239 182 187 182C189.761 182 192 184.239 192 187Z" />
    </svg>
  );
}
